import axios from 'axios';
// import Cookies from "js-cookie";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


axios.defaults.baseURL = API_BASE_URL;


/**
 *
 * @param {string} entity_url
 * @param {Array} filters
 * @param {number} limit
 */
 export const getEntity = (entity_url) => {
    return axios.get(entity_url, {headers: {'Content-Type': 'application/json'}})
  }


/**
 *
 * @param {string} entity_url
 * @param {Array} filters
 * @param {number} limit
 */
export const getEntityById = (entity_url, id) => {
  return axios.get(entity_url+"/"+id, {headers: {'Content-Type': 'application/json'}})
}



/**
 *
 * @param {string} entity_url - Url for API
 * @param {object} data - Data
 * @param {object} headers
 */

 export const postEntity = (entity_url, data, headers ) => {
    return axios.post(entity_url, data, headers)
  }