import React, {useState} from "react";
import {postResource} from '../../services/api';
import {Form,Col,Alert} from 'react-bootstrap';

function NewsletterArea() {

  const base_path = process.env.PUBLIC_URL;


  const [query1, setQuery1] = useState("");
  const [query2, setQuery2] = useState("");
  const [alert1, setAlert1] = useState(null);
  const [alert2, setAlert2] = useState(null);
  const [button1, setButton1] = useState(<i className="fa fa-paper-plane"></i>);
  const [button2, setButton2] = useState(<i className="fa fa-paper-plane"></i>);



  const HandleSubmit1 = (e) => {
    e.preventDefault();

    if(query1) {

        if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]{2,}[.][a-z]{2,3}$/.test(query1)){

            setButton1(<i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>)

            setAlert1({ success: true, message: 'Abonnement réussie' })
            setTimeout(() => {
                      setAlert1(null);
                      setButton1(<i className="fa fa-paper-plane"></i>)
                      window.location.reload()
                    }, 5000)

            // postResource('contacts', {email:query}).then(() => {

               
            //     setAlert1({ success: true, message: 'Votre email a été prise en compte. Merci !!!' });
            //     setButton1(<i className="fa fa-paper-plane"></i>)

            //   }).finally(() => {
            //         setTimeout(() => {
            //             setAlert(null);
            //             window.location.reload()
            //         }, 5000);
            //     });

        }else{
            setAlert1({ success: false, message: 'veuillez entrer une adresse email valide' });
          
        }

    }else{
        setAlert1({ success: false, message: 'veuillez renseigner ce champ' });
        

    }

  }

  const HandleSubmit2 = (e) => {
    e.preventDefault();

    if(query2) {


            setButton2(<i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>)

            setAlert2({ success: true, message: 'Votre message a été prise en compte' })
            setTimeout(() => {
                      setAlert2(null);
                      setButton2(<i className="fa fa-paper-plane"></i>)
                      window.location.reload()
                    }, 5000)

            // postResource('subscription_newsletter', {email:query}).then(() => {

               
            //     setAlert2({ success: true, message: 'Votre email a été prise en compte. Merci !!!' });
            //     setButton2(<i className="fa fa-paper-plane"></i>)

            //   }).finally(() => {
            //         setTimeout(() => {
            //             setAlert2(null);
            //             window.location.reload()
            //         }, 5000);
            //     });

    }else{
        setAlert2({ success: false, message: 'veuillez renseigner ce champ' });
        

    }

  }

const getQuery1 = (e) => {
  setQuery1(e.target.value);
}

const getQuery2 = (e) => {
  setQuery2(e.target.value);
}

  return (
    <div>
      <section
        className="section-newsletter"
        style={{
          backgroundImage:
            "url(" + base_path + "/assets/img/bg-newsletter.png" + ")",
          padding: "92px 0px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-xxl-6 col-lg-6 mb-md-4 mb-sm-4 mb-3">
              <div className="d-flex bg-white d-flex card-newsletter h-100">
                <div className="decoration deco-1 d-flex align-items-end d-none d-sm-none d-md-flex">
                  <img src={base_path + "/assets/img/Vector%202.svg"} />
                </div>
                <div
                  className="flex-grow-1 newsletter-container py-5 px-2"
                  style={{ fontSize: "13px" }}
                >
                  <h4 className="letter" style={{ color: "rgb(64,62,62)" }}>
                    Abonnez-vous à notre newsletter
                  </h4>
                  <p style={{ fontSize: "13px", color: "rgb(88,86,86)" }}>
                    Soyez les premiers à être informés de nos dernières
                    actualités.
                  </p>
                  {
                      alert1 && <Alert className="text-center" variant={alert1.success ? 'success' : 'danger'} onClose={() => setAlert1(null)} dismissible>
                        { alert1.message }
                        </Alert>
                  }
                  <form>
                    <div className="form-wrapper d-flex">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Votre email"
                        onChange={getQuery1}
                        name="subscriber-email"
                        style={{
                          borderBottomStyle: "solid",
                          borderBottomColor: "var(--bs-blue)",
                          borderRadius: "0px",
                          borderTopLeftRadius: "0",
                          borderTopRightRadius: "0",
                          borderBottomRightRadius: "0",
                          borderBottomLeftRadius: "0",
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={HandleSubmit1}
                        style={{
                          borderTopLeftRadius: "0",
                          borderBottomLeftRadius: "0",
                        }}
                      >
                        {button1}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="decoration deco-2 d-flex align-items-start">
                  <img
                    src={base_path + "/assets/img/newsletter.svg"}
                    style={{ width: "100px" }}
                    alt="newsletter"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3">
              <div className="d-flex bg-white d-flex card-newsletter h-100">
                <div className="decoration deco-1 d-flex align-items-end d-none d-sm-none d-md-flex">
                  <img
                    src={base_path + "/assets/img/emergencydeco2.png"}
                    style={{ width: "63px" }}
                  />
                </div>
                <div
                  className="flex-grow-1 newsletter-container py-5 px-3"
                  style={{ fontSize: "13px" }}
                >
                  <h4 className="letter" style={{ color: "rgb(64,62,62)" }}>
                    Une plainte ou une suggestion ?
                  </h4>
                  <p style={{ fontSize: "13px", color: "rgb(88,86,86)" }}>
                    Merci de nous aider à mieux vous servir.
                  </p>
                  {
                      alert2 && <Alert className="text-center" variant={alert2.success ? 'success' : 'danger'} onClose={() => setAlert2(null)} dismissible>
                        { alert2.message }
                      </Alert>
                  }
                  <form>
                    <div className="form-wrapper d-flex">
                      <input
                        className="form-control"
                        type="text"
                        onChange={getQuery2}
                        placeholder="Votre message"
                        name="subscriber-email"
                        style={{
                          borderBottomStyle: "solid",
                          borderBottomColor: "var(--bs-blue)",
                          borderRadius: "0px",
                          borderTopLeftRadius: "0",
                          borderTopRightRadius: "0",
                          borderBottomRightRadius: "0",
                          borderBottomLeftRadius: "0",
                        }}
                      />
                      <button
                        onClick={HandleSubmit2}
                        className="btn btn-primary"
                        type="button"
                        style={{
                          borderTopLeftRadius: "0",
                          borderBottomLeftRadius: 0,
                        }}
                      >
                        {button2}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="decoration deco-2 d-flex align-items-start">
                  <img
                    src={base_path + "/assets/img/emergency.png"}
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NewsletterArea;
