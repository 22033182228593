import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import ContentModule from '../components/moduleComponents/ContentModule';
import {getEntity} from '../services/api';
import Loader from '../common/Loader'

export default function Module() {

    const {slug} = useParams();
    const[currentmodule, setCurrentmodule] = useState(null);

    useEffect(() => {
        getEntity(`modules?name=${slug}`).then(res => {
            if(res.data.data.length>0){
                setCurrentmodule(res.data.data[0]);
            }
            
        })

    }, []);

    return (
        <>
            {!currentmodule && <Loader/>}
            {currentmodule &&<ContentModule module={currentmodule}/>}
        </>
    )
}
