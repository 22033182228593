import React, { useEffect, useState } from 'react';
import NewsletterArea from '../../common/NewsletterArea'


const ContentPage = ({page}) => {
    const base_path = process.env.PUBLIC_URL;


    return ( 

        <>
            <div className="herosection d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block"></div>
            <div className="container page-content-wrapper ">
                <div className="row">
             

                                
                    <div className={'page-title offset-2 col-md-8 text-center   fs-5'} style={{padding: '16px', fontFamily: 'Montserrat, sans-serif',fontWeight: 'bold'}}>{page && page.title}</div>
                    <div className="offset-2 col-md-8 px-4 py-3 bg-white mb-3" dangerouslySetInnerHTML={{__html:page && page.content}}></div>
                </div>
            </div>

            <NewsletterArea/>
        </>
        
        
     );
}
 
export default ContentPage;