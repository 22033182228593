import React, { useEffect, useState } from 'react';
import { getEntity } from "../../services/api";
import { useParams } from 'react-router-dom'
import NewsletterArea from '../../common/NewsletterArea'
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { format } from 'date-fns'
import frLocale from 'date-fns/locale/fr'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/bundle';


const directory_doc = process.env.REACT_APP_SERVER_ASSET;
const base_path = process.env.PUBLIC_URL;



export default function ContentModuleNews({ module }) {


    const [actualites, setActualites] = useState([])
    const [communiques, setCommuniques] = useState([])


    useEffect(() => {
        getEntity(`actualites?modules_id=${module.id}&public=1`).then(res => {
            setActualites(res.data.data)
        })

        getEntity(`actualites?modules_id=${module.id}&public=1&type=communique&in_slider=1`).then(res => {
            setCommuniques(res.data.data)
        })

    }, [])


    return (

        <div>

            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div style={{ padding: '24px 0px' }}>
                            <h5>Grands Titres</h5>
                            <div style={{ height: '4px', width: '103px', background: '#111010' }}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="simple-slider container" style={{ marginBottom: "92px" }}>
                <div className="swiper-container rounded-3">
                    <div className="swiper-wrapper">

                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }}
                            pagination={{
                                el: '.swiper-pagination',
                                type: 'bullets',
                            }}
                            // scrollbar={{ draggable: true }}
                            slidesPerView={1}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        >
                            {
                                communiques.map(c => (
                                    <SwiperSlide key={c?.id}>

                                        <div className="swiper-slide position-relative d-flex justify-content-center align-items-end" style={{ backgroundImage: "url(" + directory_doc + JSON.parse(c?.uploaded_files?.filename || '""').replace(/\%22/g, '') + ")" }}>
                                            <div className="text-white mb-0 w-100 p-5" style={{ fontFamily: 'Montserrat, sans-serif', color: 'rgb(243,245,248)', borderTopLefRadius: '0', borderTopRightRadius: '0', background: "linear-gradient(0deg, black 15%, rgba(255,255,255,0))" }}>
                                                <h1 className="px-5 fs-2 fw-bold">{c?.title}</h1>
                                            </div>
                                        </div>

                                    </SwiperSlide>
                                ))
                            }

                            {/* <SwiperSlide>

                            <div className="swiper-slide" style={{backgroundImage: "url(" + base_path + '/assets/img/hero.png'+ ")"}}></div>

                        </SwiperSlide>
                        <SwiperSlide>

                            <div className="swiper-slide" style={{backgroundImage: "url(" + base_path + '/assets/img/hero.png'+ ")"}}></div>

                        </SwiperSlide> */}
                        </Swiper>


                    </div>

                    <div className="swiper-pagination"></div>

                    <div className="d-flex flex-column swiper-button-prev d-none d-lg-block"></div>

                    <div className="swiper-button-next d-none d-lg-block"></div>
                </div>
            </div>

            <section>
                <div className="container">

                    <div style={{ padding: '24px 0px' }}>
                        <h5>Actualités</h5>
                        <div style={{ height: '4px', width: '103px', background: '#111010' }}></div>
                    </div>
                    <div className="row">

                        {
                            actualites?.map(a => (
                                <div className="col-md-4 mb-4" key={a?.id}>
                                    <div className="card info-card h-100">
                                        <img style={{ objectFit: 'cover' }} className="card-img-top w-100 d-block info-img" src={directory_doc + JSON.parse(a.uploaded_files?.filename || '""').replace(/\%22/g, '')} height={300} width={550} />
                                        <div className="card-body">
                                            <h4 className="card-title info-card.heading fs-5 fw-semibold">{a?.title}</h4>
                                            <div className="mb-0 info-card-paragraphe text-muted" dangerouslySetInnerHTML={{ __html: a?.content.substr(0, 116).concat("...") }}></div>
                                        </div>
                                        <div class="card-footer bg-white mt-0 pt-0" style={{ borderTop: 'none' }}>
                                            <hr className="mt-0" />
                                            <div className="d-flex d-xxl-flex justify-content-between align-items-center align-items-xl-center align-items-xxl-center">
                                                <div className="d-flex">
                                                    <div className=" disabled  btn-flex" style={{ fontSize: '12px', textTransform: 'capitalize' }}>{a?.created_at ? format(new Date(a?.created_at), 'dd MMMM yyyy', {locale: frLocale}) : ''}</div>
                                                </div>
                                                <a style={{ textDecoration: 'none' }} className="lire-la-suite fw-bold" href={"/actualite/" + a.id}>Lire la suite</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </section>

            <NewsletterArea />
        </div>

    )




}
