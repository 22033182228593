import React, { useEffect, useState } from 'react';
import { getEntity } from "../../services/api";
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import NewsletterArea from '../../common/NewsletterArea';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/bundle';

export default function ContentModuleMedia() {

    const base_path = process.env.PUBLIC_URL;

    const [medias, setMedias] = useState([])


    useEffect(() => {
        getEntity(`medias?public=1`).then(res => {
            setMedias(res.data.data)
        })

    }, [])

    return (
        <>
    {/* <div className="simple-slider" style={{marginBottom: "92px"}}>
        
        <div className="swiper-container">
            
            <div className="swiper-wrapper"> */}

                    {/* <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        navigation= {{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }} 
                        pagination= {{
                            el: '.swiper-pagination',
                            type: 'bullets',
                        }} 
                        scrollbar={{ draggable: true }}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        >
                        <SwiperSlide>
                            <div className="d-flex justify-content-end align-items-end align-content-center swiper-slide" style={{backgroundImage: "url(" + base_path + '/assets/img/hero.png'+ ")"}}>
                                <div className="container-fluid justify-content-start slide-container" style={{fontWWeight: '500', fontSize: '30.3429px', lineHeight: '130%',padding: "24px 40px", background: "rgba(0, 0, 0, 0.4)", fontFamily: "Montserrat, sans-serif", color: "rgb(243,245,248)"}}>
                                    <h5>Imposition des rémunérations exceptionnelles</h5>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>

                            <div className="swiper-slide" style={{backgroundImage: "url(" + base_path + '/assets/img/hero.png'+ ")"}}></div>

                        </SwiperSlide>
                        <SwiperSlide>

                            <div className="swiper-slide" style={{backgroundImage: "url(" + base_path + '/assets/img/hero.png'+ ")"}}></div>

                        </SwiperSlide>
                    </Swiper> */}
                
               
            {/* </div>
            
            <div className="swiper-pagination offset-4 col-6"></div>
            
            <div className="d-flex flex-column swiper-button-prev d-none d-lg-block"></div>
            
            <div className="swiper-button-next d-none d-lg-block"></div>
        </div> */}
    {/* </div> */}
         
    <section>
        <div className="container">
            
            <div style={{padding: '24px 0px'}}>
                <h5>Photothèque</h5>
                <div style={{ height: '4px', width: '103px', background: '#111010'}}></div>
            </div>
            <div className="row">
            {
                medias?.filter( t => t.type_medias === "phototheque" ).map( m => (
                
                    <div className="col mb-4" key={m?.id}>
                        <div className="card d-flex flex-column media" style={{height: '100%'}}>
                            <div className="d-flex d-xl-flex flex-grow-1 justify-content-center align-items-center justify-content-xl-center align-items-xl-center mediathequeCardImage" 
                            ></div>
                            <div className="p-3">
                                <h4 className="info-card.heading"><strong>{m?.title}</strong></h4>
                                <p className="mb-4 info-card.paragraphe text-muted">{m?.created_at}</p>
                                <div className="d-flex d-xxl-flex justify-content-between align-items-center align-items-xl-center align-items-xxl-center">
                                    <div className="d-flex gap-3">
                                        <a className="card-link" href={m?.photoLink} target= "_blank">Voir plus</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))      
            }
            </div>
            
        </div>
    </section>

    <section>
        <div className="container">
            
            <div style={{padding: '24px 0px'}}>
                <h5>Vidéothèque</h5>
                <div style={{ height: '4px', width: '103px', background: '#111010'}}></div>
            </div>
            <div className="row">
            {
                medias?.filter( t => t.type_medias === "videotheque" ).map( m => (

                    <div className="col mb-4">
                    
                    <div className="card d-flex flex-column media" style={{height: '100%'}}>
                        <div className="d-flex d-xl-flex flex-grow-1 justify-content-center align-items-center justify-content-xl-center align-items-xl-center mediathequeCardImage"
                            style={{
                                background:
                                    // "radial-gradient(rgba(20,20,20,0.12) 0%, #171616 100%),
                                    "url(" + base_path + "/assets/img/Frame23.png" + 'center / cover no-repeat #000000' + ")",
                                    width: '100%',
                                    height: '300px',
                              }} 
                            >
                                <img src={base_path+"/assets/img/play.png"} style={{width: '40px'}} /></div>
                        <div className="p-3">
                            <h4 className="info-card.heading"><strong>{m.title}</strong></h4>
                            <p className="mb-4 info-card.paragraphe text-muted">{m.created_at}</p>
                            <div className="d-flex d-xxl-flex justify-content-between align-items-center align-items-xl-center align-items-xxl-center">
                                <div className="d-flex gap-3">
                                    <a className="card-link" href={m.videoLink} target= "_blank">Voir plus</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ))      
            }
            </div>
        </div>
    </section>

    <NewsletterArea/>
   
    </>
    )
}
