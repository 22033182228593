import React, { useEffect, useState } from 'react';
import { getEntity } from "../../services/api";
import NewsletterArea from '../../common/NewsletterArea'

export default function ContentModuleDocument({ module }) {

    const directory_doc = process.env.REACT_APP_SERVER_ASSET;


    const [tenders, setTenders] = useState([])


    useEffect(() => {
        getEntity(`tenders?modules_id=${module.id}&public=1`).then(res => {
            setTenders(res.data.data)
        })
    }, [])


    return (
        <div>
             <section>
            <div className="herosection d-none d-sm-flex justify-content-center align-items-center backgroundAppeldoffre" style={{height: '60vh'}}>
                <div className="container">
                    <h1 className="text-center" style={{fontFamily: 'Montserrat, sans-serif', color: '#ffb800', fontWeight: 'bold'}}>Appel d'offre</h1>
                </div>
            </div>
            <div className="container pt-4">
    
                <div className="d-sm-none" style={{padding: '24px 0px'}}>
                    <h5>Les appels d'offre</h5>
                    <div style={{height: '4px', width: '103px', background: '#111010'}}></div>
                </div>
                {tenders.map(t => (
                    <div className="card border-0 shadow-md offset-lg-2 col-lg-8 mb-3" key={t?.id}>
                    <div className="card-body" style={{ border: '2px solid rgb(33 156 117)', 
                        borderRadius: '7px'
                    }}>
                        <h4 className="card-title">{t?.entity}</h4>
                        <h6 className="text-muted card-subtitle mb-2">{t.entity}</h6>
                        <h6 className="text-muted card-subtitle mb-2">dernier délai prévu pour le {t.deadline}</h6>
                        <p className="card-text" dangerouslySetInnerHTML={{__html: t.description}}></p>
                        <div className="text-end"><a target="_blank" href={directory_doc + JSON.parse(t?.uploaded_files.filename || '""')} 
                        style={{ 
                            color: '#093E73',
                            textDecoration:'none', 
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px'
                        }}>
                            TELECHARGER LE DOSSIER</a> &#62; </div>
                    </div>
                </div>
                )

                )}
            </div> 
        </section>
    
        <NewsletterArea/>
        </div>
      )

}
      