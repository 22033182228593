import React from 'react';
import Menu from './menu';
import InfoBox from './infobox';

const Header = () => {

    const base_path = process.env.PUBLIC_URL;

    const sticky = {
        paddingBottom: '22px',
        position: 'sticky',
        top: '0px',
        zIndex: '10000'
    }

    return (
        <>
            <header className="page-header bg-white" style={sticky}>
                <div className="social">
                    <div className="container gflex">
                        <div className="intouch">
                            {/* <a className="#" href="#"> <img src={base_path+"/assets/img/search.png"} alt="" /></a> */}
                            <a className="#" href="#"> <img src={base_path+"/assets/img/twitter.png"} alt="" /></a>
                            <a className="#" href="#"> <img src={base_path+"/assets/img/mail.png"} alt="" /></a>
                            {/* <div className="dropdown" style={{background: 'rgba(255,255,255,0)'}}><button className=" dropdown-toggle" aria-expanded="false" data-bs-toggle="dropdown" type="button" style={{background: "rgb(41,50,64)", boxShadow: 'none !important', color: 'white', borderBottom: '1px solid white'}}>Francais </button>
                                <div className="dropdown-menu"><a className="dropdown-item" href="#">Français</a><a className="dropdown-item" href="#">Anglais</a></div>
                            </div> */}
                        </div>
                    </div>
                </div>

                

                <div className="container bg-white ">
                    <div className="gflex justify-content-between align-items-middle">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/img/logo-DGPED.png" alt="" width="100px" />
                            </a>
                        </div>
                        <div className="gouv">
                            <a href="#">
                                <img src="/assets/img/logoMinistere.png" alt="" width="234px" />
                            </a>
                        </div>
                    </div>

                </div>

                <Menu/>

                {/* <InfoBox/> */}

            </header>
        </>
    );
}

export default Header;