import React, { useState, useEffect } from "react";
import { getEntity } from "../../services/api";
import { useLocation, useParams } from "react-router-dom";

// Fonction utilitaire pour capitaliser la première lettre d'une chaîne
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const routes = [
  { path: '/', name: 'Accueil' },
  { path: '/section/:slug', name: (slug) => `${capitalizeFirstLetter(slug)}` },
  // Ajoutez d'autres routes ici si nécessaire
];

const getPageName = (pathname, params) => {
  for (let route of routes) {
    const regex = new RegExp(`^${route.path.replace(/:\w+/g, '[^/]+')}$`);
    if (regex.test(pathname)) {
      if (typeof route.name === 'function') {
        const slug = params[route.path.match(/:(\w+)/)[1]]; // Extract parameter name
        return route.name(slug);
      }
      return route.name;
    }
  }
  return 'Page';
};

function ContentModuleDocument({ module }) {
  const directory_doc = process.env.REACT_APP_SERVER_ASSET;
  const base_path = process.env.PUBLIC_URL;
  const [doc, setDoc] = useState([]);
  const location = useLocation();
  const params = useParams();
  const pageName = getPageName(location.pathname, params);

  useEffect(() => {
    getEntity(`documentations?modules_id=${module.id}&public=1`).then((res) => {
      setDoc(res.data.data);
    });
  }, [module.id]);

  return (
    <div>
      <h1
        className="fs-1 fw-bold text-center py-3"
        style={{ fontFamily: "Montserrat, sans-serif", marginBottom: "20px", backgroundColor: '#008558', color: '#ffffff' }}
      >
        {pageName}
      </h1>
      <div className="container mb-3">
        <div className="row">
          {doc?.map((d) => (
            <div className="col mb-2 col-lg-4 col-md-4" key={d?.id}>
              <div className="card">
                <div className="card-body text-center text-sm-center text-md-start text-lg-start text-xl-start text-xxl-start d-flex flex-column justify-content-center align-items-center gap-2">
                  <img src={base_path + "/assets/img/file1.png"} style={{ width: "100px" }}/>
                  <h4
                    className="text-center d-sm-flex d-md-flex align-items-sm-center align-items-md-center card-title d-block"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "14px",
                      fontWeight: "boldler",
                    }}
                  >
                    {d.title}
                  </h4>
                  <a
                    className="btn btn-primary"
                    href={directory_doc + JSON.parse(d?.uploaded_files.filename || '""')}
                    // href={
                    //   directory_doc +
                    //   encodeURIComponent(JSON.parse(d?.uploaded_files?.filename || '""')).replace(
                    //     /\%22/g,
                    //     ""
                    //   )
                    // }
                    target="_blank"
                    type="button" rel="noreferrer"
                  >
                    Télécharger
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContentModuleDocument;
