import React, {useState, useEffect} from 'react';
import { getEntity } from '../../services/api';

const Menu = () => {

    const [menus, setMenus] = useState([]);
    const [modules, setModules] = useState([]);
    const [pages, setPages] = useState([]);

    var queryParams = window.location.pathname.split('/');

    const selected = {
        border: '4px black solid',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        padding:'8px',
        borderColor: '#008558',
        color:'#008558'
    }


     useEffect(() => {

        getMenu();
       
     }, [])

     const getMenu = () => {
         getEntity(`menus?public=1`).then( res => {
            setMenus(res.data.data)
        })

         getEntity('modules?public=1').then( res => {
            setModules(res.data.data)
        })

         getEntity('pages?public=1').then( res => {
            setPages(res.data.data)
        })
    }


    const generateLink = menu =>{

            let menuInModule = modules.find( k => k.menus_id === menu.id );
            let menuInPage = pages.find( p => p.menus_id === menu.id );
    
                if(menuInModule) return `/section/${menuInModule.name}`
                if(menuInPage) return `/page/${menuInPage.permalink}`
    
                return '/'
    
    }

    const getMenusParent = (menus) => {
        const menuMap = menus.filter(m=>m.parent_id === null)
        menuMap.map((mp,indexp)=>{
            menuMap[indexp]["children"] = []
            menus.map(mn=>{
                if(mn.parent_id === mp.id) 
                menuMap[indexp].children.push(mn)
            })
        })

        return menuMap
    }

    const displayMenus = getMenusParent(menus)

     
    return(
        <>
       
            <nav className="navbar navbar-light navbar-expand-md d-xl-flex py-2" style={{ boxShadow: "0px 8px 16px rgba(33,37,41,0.25)" }}>
                    <div className="container"><button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navcol-1">
                        <span className="visually-hidden">Toggle navigation</span>
                        <span className="navbar-toggler-icon"></span></button>
                        <div id="navcol-1" className="collapse navbar-collapse">
                            <ul className="navbar-nav" style={{width: "100%", display:"flex", justifyContent: "space-between"}}>

                            <li className="nav-item"><a className="nav-link" style = { window.location.pathname === '/' ? selected : {color: 'black'} } href="/">Accueil</a></li>

                            {displayMenus && displayMenus.map((menu) => (

                                <>
                                    {menu.children.length > 0 
                                    
                                    ?
                                        
                                        <li className="nav-item dropdown" key={`parentmenu${menu.id}`}>
                                            <a className="nav-link dropdown-toggle" role="navigation" id={`dropdownMenuLink${menu.id}`} data-bs-toggle="dropdown" 
                                                aria-expanded="false" href={generateLink(menu)}>
                                                {menu.title}
                                            </a>
    
                                                <ul className="dropdown-menu mt-2" aria-labelledby={`dropdownMenuLink${menu.id}`}>
                                                    {menu.children.map((child) => (
                                                        <li key={`submenu${child.id}`}><a className="dropdown-item" href={generateLink(child)} >{child.title}</a></li>
                                                     ))
                                                    }
                                                </ul>
                                        </li>

                                    :

                                        <li className="nav-item" key={`menu${menu.id}`}><a className="nav-link" href={generateLink(menu)}>{menu.title}</a></li>

                                        // style = {window.location.pathname.split('/')[2] === menu.permalink ? selected : {color: 'black'} }

                                    }
            
                                </>

                            ))}

                            {/* <li className="nav-item" style={{fontFamily: "Montserrat, sans-serif;"}}></li> */}
                            </ul>
                        </div>
                    </div>
            </nav>
        </>
    );
}

export default Menu;