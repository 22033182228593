import React, {useEffect,useState} from 'react'
import {getEntityById} from "../../services/api"
import { useParams } from 'react-router-dom'
import NewsletterArea from '../../common/NewsletterArea'

function ContentDetailActualite() {

    const directory_doc = process.env.REACT_APP_SERVER_ASSET;
    const base_path = process.env.PUBLIC_URL;
    const [actualite, setActualites] = useState([])
    const [bannerImg, setBannerImg] = useState('')
    const {slug} = useParams();


    useEffect(() => {
        getEntityById("actualites", slug).then(res => {
            setActualites(res.data.data)

            if(res.data.data?.uploaded_files) {
                setBannerImg(
                    directory_doc + encodeURIComponent(JSON.parse(res.data.data?.uploaded_files?.filename || '""')).replace(/\%22/g, '')
                )
            }
        })
    }, [])


  return (
    <div>
        {actualite ?
            <section>
                <div className="herosection d-sm-flex justify-content-center align-items-center" style={{height: '80vh', background: `radial-gradient(rgba(36,44,55,0.67) 2%, #242c37 100%), url("${bannerImg}") center / cover no-repeat, rgba(25,135,84,0)`, marginBottom: '60px',color: '#ffb800'}}>
                    
                </div>
                 <div className="container col-md-6 mb-3 text-left page-content-wrapper" style={{backgroundColor: 'white', padding: '24px 64px'
                }} >
                         <h2 className="mb-4" style={{fontFamily: 'Montserrat, sans-serif', color: 'black', fontWeight: 'bolder'}}>{actualite?.title}</h2>
                         <div className="" style= {{textAlign: "justify", fontFamily: "Montserrat, sans-serif" }} dangerouslySetInnerHTML={{__html: actualite?.content}}></div>
                </div>
            </section>
        
        :''
        }
        <NewsletterArea/>
    </div>
  );
}

export default ContentDetailActualite