import React from 'react';

const NotFound = () => {
    return ( 

        <h5 className='text-center mb-5'>Page non trouvée </h5>

    );
}
 
export default NotFound