import React, {useEffect, useState} from "react";
import ContentModuleTender from './ContentModuleTender';
import ContentModuleContact from './ContentModuleContact';
import ContentModuleMedias from './ContentModuleMedias';
import ContentModuleNews from './ContentModuleNews';
import ContentModuleDocument from "./ContentModuleDocument";
import NotFound from '../../common/NotFound';


const ContentModule = ({module}) => {
    
    const type = ["tender","news","contacts","medias","report","magazine","textsOfLaws","relashionshipChapters"];

    return ( 
        <>
        { (type.filter(t => t == module.type_module).length == 0) && <NotFound/>}
        { module.type_module === type[0] && <ContentModuleTender module={module}/> }
        { module.type_module === type[1] && <ContentModuleNews module={module} /> }
        { module.type_module === type[2] && <ContentModuleContact module={module}/> }
        { module.type_module === type[3] && <ContentModuleMedias module={module}/> }    
        { (module.type_module === type[4] || type[5] || type[6] || type[7]) && <ContentModuleDocument module={module}/> }

    </>
     );
}
 
export default ContentModule;