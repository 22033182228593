import React, {useEffect,useState} from 'react'
import {getEntityById} from "../../services/api"
import { useParams } from 'react-router-dom'



function ContentDetailEvent() {

    const directory_doc = process.env.REACT_APP_SERVER_ASSET;
    const base_path = process.env.PUBLIC_URL;
    const [event, setEvent] = useState([])
    const [themes, setThemes] = useState([])
    const {slug} = useParams();


    useEffect(() => {
        getEntityById("events", slug).then(res => {
            setEvent(res.data.data)
        })

        getEntityById("eventThemes", slug).then(res => {
            setThemes(res.data.data)
        })

    }, [])    


  return (
    <div>
       
        {event ?
        
        <div className="container">
            
            <div className="row py-4" style={{fontFamily: 'Montserrat, sans-serif'}}>

                <div className="col event-detail">
                    <div className="envent-heading">
                        <h3 className="pb-4" style={{fontWeight: 'bold'}}>{event?.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: event?.description}} />
                        <div className="event-date d-flex align-items-center gap-2"><img src={base_path+"/assets/img/simple-line-icons_calender.png"} width="24"/>
                            <h5 style={{fontWeight: 'bold'}}>Du {event?.start_date} au {event?.end_date}</h5>
                        </div>
                        <div className="event-date d-flex align-items-center gap-2 pb-4"><img src={base_path+"/assets/img/carbon_location.png"} width="24"/>
                            <h5 style={{fontWeight: 'bold'}}>{event?.location}</h5>
                        </div>
                    </div>
                </div>

                <div className="col bg-white programs p-4 rounded-3 shadow-md">
                    <h5 className="mb-4" style={{fontWeight: 'bold'}}>Programme de l’évènement</h5>
                    <div className="d-flex flex-column gap-3">
                        <div className="pb-1 border-bottom program-item">
                            <h6>Thème</h6>
                            <p><strong>{themes?.title}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :''
        }
        
        <section className="mb-5 d-none d-md-flex" style={{backgroundImage: "url(" + directory_doc + event?.event_image + 'top / cover no-repeat' + ")"}}></section>
    </div>
  );
}

export default ContentDetailEvent