import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./layouts/header/header";
import Footer from "./layouts/footer";
import Home from "./pages/home";
import Page from "./pages/Page";
import Module from "./pages/Module";
import NotFound from './common/NotFound';
import ContentDetailEvent from "./components/moduleComponents/ContentDetailEvent";
import ContentDetailActualite from "./components/moduleComponents/ContentDetailActualite";





 function App() {
  return (
    <BrowserRouter>
    <Header/>
      <Routes>
          <Route path="/*" element={<NotFound/>} />
          <Route path="/" element={<Home />} />
          <Route path="/section/:slug" element={<Module />} />
          <Route path="/page/:slug" element={<Page/>} />
          <Route path="/evenement/:slug" element={<ContentDetailEvent/>} />
          <Route path="/actualite/:slug" element={<ContentDetailActualite/>} />

      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;

